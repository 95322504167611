// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Merriweather);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Merriweather";
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar button {
  color: white;
  background-color: #28245e;
  border-radius: 6px;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 15px;
}
.navbar button:hover {
  color: #28245e;
  background-color: white;
}
.bluebutton button {
  color: white;
  background-color: #28245e;
  border-radius: 6px;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 15px;
}
.bluebutton button:hover {
  color: #28245e;
  background-color: white;
}
.no-link Link { 
  cursor: default; 
  color: black; 
  text-decoration:none; 
}
#active {
  color: white;
  background-color: #28245e;
}
#inactive {
  color: #28245e;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,cAAc;EACd,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,cAAc;EACd,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,uBAAuB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Merriweather');\n\n* {\n  font-family: \"Merriweather\";\n}\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n.navbar button {\n  color: white;\n  background-color: #28245e;\n  border-radius: 6px;\n  padding: 2px 5px;\n  cursor: pointer;\n  font-size: 15px;\n}\n.navbar button:hover {\n  color: #28245e;\n  background-color: white;\n}\n.bluebutton button {\n  color: white;\n  background-color: #28245e;\n  border-radius: 6px;\n  padding: 2px 5px;\n  cursor: pointer;\n  font-size: 15px;\n}\n.bluebutton button:hover {\n  color: #28245e;\n  background-color: white;\n}\n.no-link Link { \n  cursor: default; \n  color: black; \n  text-decoration:none; \n}\n#active {\n  color: white;\n  background-color: #28245e;\n}\n#inactive {\n  color: #28245e;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
